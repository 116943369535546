import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import OurStory from "../views/OurStory.vue";
import Wedding from "../views/Wedding.vue";
import Photos from "../views/Photos.vue";
// import Registry from "../views/Registry.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/registry",
  //   name: "Registry",
  //   component: Registry,
  // },
  // {
  //   path: "/our-story",
  //   name: "OurStory",
  //   component: OurStory,
  // },
  {
    path: "/wedding",
    name: "Wedding",
    component: Wedding,
  },
  {
    path: "/photos",
    name: "Photos",
    component: Photos,
  },

  // THIS PATH GOES LAST!!!
  {
    path: "404",
    alias: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    // need to login
  } else {
    next();
  }
});

export default router;
