<template>
    <b-container id="ourInfo" align-h="center">
                <b-row>
            <h2 id="married">are getting married on</h2> 
        </b-row>
        <b-row align-h="center">
            <b-col cols="4">
                <h2 id="weddingDate"> {{ weddingDate }} </h2>
            </b-col>
        </b-row>
        <b-row align-h="center">
            <h2 id="that">that's in</h2>
            <h2 id="countdown"><span id="days">{{ countdown }}</span> DAYS!</h2>
        </b-row>
    </b-container>
</template>

<style scoped>
    #that{
        font-family: 'Hero', sans-serif;
        font-size: 1.3rem;
        padding-bottom: 0px;
        margin-top: 10px;
    }
    #married{
        font-family:'Hero', sans-serif;
        font-size: 1.3rem;
    }
    #and{
        font-size: 3rem;
    }
    h2{
        line-height: 1;
        font-family: 'quasimoda', sans-serif;
        color: #58595b;
        font-size: 2rem;
        font-weight: lighter;
        -webkit-letter-spacing: 1px;
        -moz-letter-spacing: 1px;
        -ms-letter-spacing: 1px;
        letter-spacing: 1px;
        margin: 8px 0;
        padding-right: 1rem;
        padding-bottom: 10px;
    }
    #weddingDate{
        font-family: 'oskar', sans-serif;
        font-weight: 800;
        border-style: solid;
        padding: 15px;
        margin-bottom: 20px;
    }@media(max-width: 414px){#weddingDate{
        margin-right: -20px;
        margin-left: -20px;
    }}
    #countdown{
        font-family:'Hero';
        font-size: 2rem;
        font-weight: normal;
        color: #c48189;
    }
    #countdown:hover{
        font-size:2.2rem;
        font-weight: bold;
    }
</style>

<script>
export default {
    name: "ourInfo",
    data() {
        return {
            bride: "Kino",
            groom: "Felix",
            weddingDate: "7 March 2021",
            countdown: 0
        };
    },
    methods: {
        dayCount(){
            // set wedding date
            const weddingDay = Date.parse('7 Mar 2021 10:00:00 UTC+8');
            // let countdown;
            //  get today's date
            let today = Date.now();

            // let daysLeft = weddingDay - today;

            this.countdown = weddingDay - today;
            // Time calculations for days, hours, minutes and seconds
			let days = Math.floor(this.countdown / (1000 * 60 * 60 * 24));
            let hours = Math.floor(this.countdown / (1000 * 60 * 60));

            if(hours > 48){
                this.countdown = days;
            } else if (hours <= 48 && hours > 10){
                this.countdown = days;
                // document.getElementById('countdown').innerHTML = `<h2>${days} DAY!!</h2>`;
                document.getElementById('that').innerHTML = `<h2 id="that" style="font-size:1.3rem;" >that's</h2>`
                document.getElementById('countdown').innerHTML = `<h2>TOMORROW!!</h2>`;
            }  else if (hours <= 10 && hours > 0){
                this.countdown = days;
                // document.getElementById('countdown').innerHTML = `<h2>${days} DAY!!</h2>`;
                document.getElementById('that').innerHTML = `<h2 id="that" style="font-size:1.3rem;" >that's</h2>`
                document.getElementById('countdown').innerHTML = `<h2>TODAY!!</h2>`;
            }  
            else{
                document.getElementById('married').innerHTML = `<h2 id="married" style="font-size:1.3rem; margin: 8px 0;"> were married on </h2>`;
                document.getElementById('that').remove();
                document.getElementById('countdown').innerHTML = "<h2>We'll tell you when the baby is on the way!!!</h2>";
            }
        }
    },
    mounted(){
        this.dayCount()
    }
}
</script>