<template>
  <b-container fluid id="app">
    <Header />
        <OurName />
        <Countdown />
    <Navigation />
    <div class="css-mf7073"></div>
    <router-view />
    <Footer />
  </b-container>
</template>

<script>
import Popper from 'popper.js'
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
import Header from '@/components/Header.vue'
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import OurName from '@/components/OurName.vue'
import Countdown from '@/components/Countdown.vue'

export default {
  name: 'App',
  components: {
    Header,
    Navigation,
    OurName,
    Countdown,
    Footer
  }
}
</script>

<style>
@font-face {
  font-family: 'rocketto-bold';
  src: local('rocketto-bold'),
  url("~@/assets/fonts/RockettoBold.ttf") format("truetype");
}
@font-face {
  font-family: 'quasimoda';
  src: local('quasimoda'),
  url("~@/assets/fonts/QuasimodaMedium.ttf") format("truetype");
}
@font-face {
  font-family: 'calgary-script-ot';
  src: local('calgary-script-ot'),
  url("~@/assets/fonts/CalgaryScriptRegular.ttf") format("truetype");
}
@font-face {
  font-family: 'Hero';
  src: local('Hero'),
  url("~@/assets/fonts/Hero.ttf") format("truetype");
}
@font-face {
  font-family: 'oskar';
  src: local('oskar'),
  url("~@/assets/fonts/Oskar One Light.otf") format("opentype");
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin:0px;
  padding: 0px;
  background-color: #fdfcf8;
}
b-container{
  height: 90vh;
}
</style>
