<template>
<div>
<b-container>
  <b-row align-h="center">
    <b-col lg="10" sm="12">
      <b-carousel 
        id="carousel-fade"
        background="#fdfcf8"
        fade
        indicators
        style="text-shadow: 0px 0px 2px #333; font-family:'quasimoda', sans-serif;"
  >
    <b-carousel-slide v-for="photo in photos"  :key="photo" :caption="photo.caption">
      <!-- :img-src="photo.photo" -->
      <template #img>
        <b-img
          :src="photo.photo"
          v-bind="mainProps"
          fluid
          style="border-radius:20px;"
          ></b-img>
      </template>
    </b-carousel-slide>
  </b-carousel>
    </b-col>
  </b-row>
  
</b-container>
</div>
</template>

<style scoped>
*{
  font-family:'quasimoda'
}
</style>

<script>
export default{
  data() {
    return {
      mainProps:{blank: false, blankColor: '#fdfcf8', width: 1024, height: 480, class: 'm1'},
      photos:[          
        {photo:"https://s3-us-west-2.amazonaws.com/kinoandfelix.com/img/portfolio_1.jpg"},
        {photo:"https://s3-us-west-2.amazonaws.com/kinoandfelix.com/img/IMG_0584.JPG",caption:""},
  
        {photo:"https://s3-us-west-2.amazonaws.com/kinoandfelix.com/img/SHIKIN%26FELIX-5.jpeg"}, {photo:"https://s3-us-west-2.amazonaws.com/kinoandfelix.com/img/SHIKIN%26FELIX-7.jpeg"},
        {photo:"https://s3-us-west-2.amazonaws.com/kinoandfelix.com/img/SHIKIN%26FELIX-13.jpeg"},
        {photo:"https://via.placeholder.com/1920x1080/c0c0c0?text=+", caption:"Wedding photos coming soon..."}
        
          ]
    }
  }
}
    
</script>