<template>
    <b-container>
        <b-row align-h="center">
          <b-col lg="8" sm="12">
        <b-img v-bind="mainProps" fluid rounded alt="The Villa at Singapore Botanic Gardens" src="https://s3-us-west-2.amazonaws.com/kinoandfelix.com/img/theVilla.jpg"></b-img>
        <div class="css-mf7073"></div>
          </b-col>
        </b-row>
        <b-row>
          <h2 id="venue">The Villa</h2>
        </b-row>
        <b-row class="subvenue">
          <h2>at</h2>
          <h2>Singapore Botanic Gardens</h2>
        </b-row>
        
        
    </b-container>
</template>

<style scoped>
* {
    font-family: 'Hero';
    color: #58595b;
}
/* b-img {
    width: 40%;
} */
#venue{
    font-family: 'Hero';
    font-size: 2rem;
    font-weight: 600;
    padding: 10px 0 0 0;
    margin-top: 2rem;
    margin-bottom: 0px;
}
.subvenue h2{
  font-size: 1.1rem;
}
</style>

<script>
  export default {
    data() {
      return {
        mainProps: { blank: false, blankColor: '#777', width: 1024, height: 480, class: 'm1' }
      }
    }
  }
</script>