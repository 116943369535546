<template>
    <b-container id="ourInfo" align-h="center">
        <b-row>
            <b-col>
                <h1 id="ourName">{{ bride }} <span id="and">&</span> {{groom}}</h1>
            </b-col>
        </b-row>
    </b-container>
</template>

<style scoped>

    #ourName{
        line-height: 1;
        font-family: 'oskar', sans-serif;
        color: #2B547e;
        font-size: 6rem;
        font-weight: normal;
        letter-spacing: 4px;
        padding-bottom: 5px;
        margin: 0px 0;
        padding-top: 0px;
    }
    #that{
        font-family: 'Hero', sans-serif;
        font-size: 1.3rem;
        padding-bottom: 0px;
        margin-top: 10px;
    }
    #married{
        font-family:'Hero', sans-serif;
        font-size: 1.3rem;
    }
    #and{
        font-size: 3rem;
    }
    h2{
        line-height: 1;
        font-family: 'quasimoda', sans-serif;
        color: #58595b;
        font-size: 2rem;
        font-weight: lighter;
        -webkit-letter-spacing: 1px;
        -moz-letter-spacing: 1px;
        -ms-letter-spacing: 1px;
        letter-spacing: 1px;
        margin: 8px 0;
        padding-right: 1rem;
        padding-bottom: 10px;
    }
    #weddingDate{
        font-family: 'oskar', sans-serif;
        font-weight: 800;
        border-style: solid;
        padding: 15px;
        margin-bottom: 20px;
    }@media(max-width: 414px){#weddingDate{
        margin-right: -20px;
        margin-left: -20px;
    }}
    #countdown{
        font-family:'Hero';
        font-size: 2rem;
        font-weight: normal;
        color: #c48189;
    }
    #countdown:hover{
        font-size:2.2rem;
        font-weight: bold;
    }

</style>

<script>
export default {

    name: "ourInfo",
    data() {
        return {
            bride: "Kino",
            groom: "Felix",
            weddingDate: "7 March 2021",
            countdown: 1
        };
    },
    // methods: {
    //     dayCount(){
    //         // set wedding date
    //         const weddingDay = Date.parse('5 Mar 2021 10:00:00 UTC+8');
    //         // let countdown;
    //         //  get today's date
    //         let today = Date.now();

    //         // let daysLeft = weddingDay - today;

    //         this.countdown = weddingDay - today;
    //         // Time calculations for days, hours, minutes and seconds
	// 		let days = Math.floor(this.countdown / (1000 * 60 * 60 * 24));

    //         if(days > 1){
    //             this.countdown = days;
    //         } else if (days >= 0 ){
    //             this.countdown = days + 1;
    //             document.getElementById('countdown').innerHTML = `<h2>${days + 1} DAY!!</h2>`;
    //         } else {
    //             document.getElementById('married').innerHTML = `<h2 id="married" style="font-size:1.3rem; margin: 8px 0;"> were married on </h2>`;
    //             document.getElementById('that').remove();
    //             document.getElementById('countdown').innerHTML = "<h2>We'll tell you when the baby is on the way!!!</h2>";
    //         }
    //     }
    // },
    // mounted(){
    //     this.dayCount()
    // }
} 
</script>